export const permissions: Record<
  string,
  {
    roles: string[]
    nickname: string[]
  }
> = {
  /* pages */
  homePage: {
    roles: ['dev', 'media_buyer', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  createPage: {
    roles: ['dev', 'admin', 'lead_spyer', 'spyer', 'youtube_spyer'],
    nickname: ['qa'],
  },
  editPage: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  favoritesPage: {
    roles: ['dev', 'media_buyer', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  appendPage: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  metricsPage: {
    roles: ['dev', 'media_buyer', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  logsPage: {
    roles: ['dev', 'admin'],
    nickname: [],
  },
  /* homePage filter fields */
  searchBySpyer: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  searchByCRM: {
    roles: ['dev', 'admin'],
    nickname: ['qa'],
  },
  searchByRedirectPath: {
    roles: ['dev', 'admin'],
    nickname: ['qa'],
  },
  searchByRegExp: {
    roles: ['dev', 'admin', 'media_buyer'],
    nickname: ['qa'],
  },
  /* homePage sort & filters in header */
  ourPosts: {
    roles: ['dev', 'admin'],
    nickname: ['qa'],
  },
  platformPosts: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['madison', 'qa'],
  },
  /* post card */
  postCreateBy: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  postRemove: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  postAutologin: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  postRedirectPath: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  postEdit: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  /* create page */
  createYTType: {
    roles: ['dev', 'admin', 'youtube_spyer'],
    nickname: ['qa'],
  },
  /* metrics page */
  effectivity: {
    roles: ['dev', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  analytics: {
    roles: ['dev', 'admin', 'media_buyer'],
    nickname: ['qa'],
  },
  adminTabs: {
    roles: ['dev', 'admin'],
    nickname: ['qa'],
  },
  /* devices */
  devices: {
    roles: ['media_buyer'],
    nickname: [''],
  },
  /* redirect */
  redirectHomePage: {
    roles: ['dev', 'media_buyer', 'admin', 'lead_spyer'],
    nickname: ['qa'],
  },
  /* devTools */
  openDevTools: {
    roles: ['dev'],
    nickname: [''],
  },
}

export const spyers = [
  'diamond',
  'gold',
  'silver',
  'obsidian',
  'cobalt',
  'emerald',
  'quartz',
  'ruby',
  'amber',
  'topaz',
  'ivory',
  'amethyst',
  'onyx',
  'sapphire',
  'aquamarine',
  'titanium',
  'chrome',
  'scoria',
  'basalt',
  'marble',
  'azurite',
  'sodalite',
  'bismuth',
  'fluorite',
  'tourmaline',
  'malachite',
  'scolecite',
  'pyrite',
  'agate',
  'carneole',
  'wulfenite',
  'manganite',
]

export const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: false },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: false },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: false },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
]

export const typesAppend = [
  { value: 0, label: 'Celebrity' },
  { value: 1, label: 'Countries' },
  { value: 2, label: 'Funnels' },
]
