import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLogger } from '@hooks/useLogger'
import { usePermissions } from '@hooks/usePermissions'

export function AccessDenied({ noSecure = false }: { noSecure?: boolean }) {
  const navigate = useNavigate()

  const { access } = usePermissions()
  const { logger } = useLogger()

  useEffect(() => {
    !noSecure && logger('navigate-access-denied', { link: window.location.href })

    const timeout = setTimeout(() => {
      navigate(access('redirectHomePage') ? '/' : '/create')

      clearTimeout(timeout)
    }, 1000)
  }, [])

  return (
    <main style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: noSecure ? '100vh' : 'auto' }}>
      <img
        src={noSecure ? '/404.png' : '/access-denied.png'}
        alt="access denied"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </main>
  )
}
