import { fetchDevices } from 'aws-amplify/auth'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuthenticator } from '@aws-amplify/ui-react'

import { Header } from '@components/Header'

import { useDevToolsDetector } from '@hooks/useDevToolsDetector'
import { useLogger } from '@hooks/useLogger'
import { usePermissions } from '@hooks/usePermissions'

import { AppLoader } from '_ui/Loader'

import { fetchUser } from '@/store/global/global.slice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

import type { LayoutProps } from './Layout.props'

export function Layout({ ...props }: LayoutProps) {
  const dispatch = useAppDispatch()
  const { roles, loading } = useAppSelector((state) => state.global)

  const { signOut } = useAuthenticator((context) => [context.signOut])

  const { access } = usePermissions()
  const { logger } = useLogger()

  useEffect(() => {
    dispatch(fetchUser())
  }, [dispatch])

  useEffect(() => {
    if (!roles) return

    if (access('devices')) {
      fetchDevices().then((res) => {
        res.length > 1 && signOut()
      })
    }
  }, [roles, signOut])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' || !access('openDevTools')) {
      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault()
        logger('user-context-menu', { url: window.location.href })
      }

      const handleKeyDown = (event: KeyboardEvent) => {
        // Handle Ctrl+S and Ctrl+U
        if (event.ctrlKey && ['s', 'u'].includes(event.key.toLowerCase())) {
          event.preventDefault()
          logger('user-context-menu', { url: window.location.href, key: `Ctrl+${event.key.toUpperCase()}` })
        }

        // Handle Ctrl+Shift+C and F12
        if ((event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'c') || event.key === 'f12') {
          event.preventDefault()
          event.stopPropagation()
          logger('user-devtools-opened', { url: window.location.href, key: event.key === 'f12' ? 'F12' : 'Ctrl+Shift+C' })
        }

        // Handle Ctrl+Shift+I
        if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'i') {
          event.preventDefault()
          logger('user-devtools-opened', { url: window.location.href, key: 'Ctrl+Shift+I' })
        }

        // Handle Ctrl+Shift+J
        if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'j') {
          event.preventDefault()
          logger('user-devtools-opened', { url: window.location.href, key: 'Ctrl+Shift+J' })
        }

        // Handle Ctrl+Shift+K (in some browsers)
        if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'k') {
          event.preventDefault()
          logger('user-devtools-opened', { url: window.location.href, key: 'Ctrl+Shift+K' })
        }

        // Handle Ctrl+Shift+S (in some browsers)
        if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 's') {
          event.preventDefault()
          logger('user-devtools-opened', { url: window.location.href, key: 'Ctrl+Shift+S' })
        }
      }

      document.addEventListener('contextmenu', handleContextMenu)
      document.addEventListener('keydown', (e) => handleKeyDown(e))

      return () => {
        document.removeEventListener('contextmenu', handleContextMenu)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [])

  useDevToolsDetector(() => {
    window.location.reload()
  })

  return (
    <>
      <Header />

      {loading ? <AppLoader /> : <Outlet />}
    </>
  )
}
