import type { ServerResponse } from '@assets/types/globals'

import { api } from '@/store/api'

import type { CreateLogsDTOInterface, LogsDTOInterface, LogsResponseInterface } from './types'

export const logsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLogs: builder.query<LogsResponseInterface, LogsDTOInterface>({
      query: (params) => ({
        url: `/logs`,
        params,
      }),
      transformResponse: (response: ServerResponse<LogsResponseInterface>) => response.data,
      providesTags: ['logs'],
    }),
    createLog: builder.mutation<void, CreateLogsDTOInterface>({
      query: ({ payload, ...params }) => ({
        url: `/logs`,
        method: 'POST',
        params,
        body: { payload },
      }),
      invalidatesTags: ['logs'],
    }),
  }),
})

export const { useGetLogsQuery, useCreateLogMutation } = logsApi
