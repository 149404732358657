import classNames from 'classnames'
import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { useAuthenticator } from '@aws-amplify/ui-react'

import { ReactComponent as CreateIcon } from '@assets/icons/badge-plus.svg'
import { ReactComponent as BlocksIcon } from '@assets/icons/blocks.svg'
import { ReactComponent as ChartIcon } from '@assets/icons/chart.svg'
import { ReactComponent as HomeIcon } from '@assets/icons/home.svg'
import { ReactComponent as StarIcon } from '@assets/icons/star.svg'
// import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg'
import { ReactComponent as UserIcon } from '@assets/icons/user.svg'

import { usePermissions } from '@hooks/usePermissions'

import { Button } from '_ui/Button'

import s from './Header.module.scss'
import type { HeaderProps } from './Header.props'

const homePage = {
  label: 'Home',
  path: '/',
  icon: <HomeIcon />,
}
const createPage = {
  label: 'Create',
  path: '/create',
  icon: <CreateIcon />,
}
const appendPage = {
  label: 'Append',
  path: '/append',
  icon: <BlocksIcon />,
}
const favoritesPage = {
  label: 'Favorites',
  path: '/favorites',
  icon: <StarIcon />,
}
const metricsPage = {
  label: 'Metrics',
  path: '/metrics',
  icon: <ChartIcon />,
}

export function Header({ ...props }: HeaderProps) {
  const navigate = useNavigate()

  const { access } = usePermissions()

  const navigation = [
    ...(access('homePage') ? [homePage] : []),
    ...(access('createPage') ? [createPage] : []),
    ...(access('appendPage') ? [appendPage] : []),
    ...(access('favoritesPage') ? [favoritesPage] : []),
    ...(access('metricsPage') ? [metricsPage] : []),

    // {
    //   label: 'Delete',
    //   path: '/delete',
    //   icon: <TrashIcon />,
    // },
  ]

  const { user, route, signOut, authStatus } = useAuthenticator((context) => [context.user, context.route, context.signOut])

  return (
    <header className={s.header}>
      <Link
        to="/"
        className={s.headerLogo}
      >
        <img
          src="/logo.png"
          alt="logo"
          width={182.7}
          height={32}
        />
      </Link>
      <div className={s.headerNav}>
        {navigation.map((nav) => (
          <NavLink
            key={nav.path}
            to={nav.path}
            className={({ isActive }) => classNames(s.headerNavItem, { [s.active]: isActive })}
          >
            <span>{nav.label}</span>
            {nav.icon}
          </NavLink>
        ))}
      </div>

      <div className={s.headerAccount}>
        <div className={s.headerUser}>
          <UserIcon />
          <span>{user.username}</span>
        </div>

        <Button
          variant="empty"
          onClick={() => {
            signOut()
            navigate(access('redirectHomePage') ? '/' : '/create')

            document.title = '¯\\(°_o)/¯'
          }}
        >
          Sign Out
        </Button>
      </div>
    </header>
  )
}
