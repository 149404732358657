import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { isExternalURL } from '@assets/helpers/utils'

import s from './Button.module.scss'
import type { ButtonProps } from './Button.props'

export function Button({ variant, type = 'button', className, children, href, ...props }: ButtonProps) {
  if (href) {
    if (isExternalURL(href)) {
      return (
        <a
          className={classNames(s.button, className, {
            [s.fill]: variant === 'fill',
            [s.outline]: variant === 'outline',
            [s.empty]: variant === 'empty',
          })}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )
    }

    return (
      <Link
        className={classNames(s.button, className, {
          [s.fill]: variant === 'fill',
          [s.outline]: variant === 'outline',
          [s.empty]: variant === 'empty',
        })}
        to={href}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      type={type}
      className={classNames(s.button, className, {
        [s.fill]: variant === 'fill',
        [s.outline]: variant === 'outline',
        [s.empty]: variant === 'empty',
      })}
      {...props}
    >
      {children}
    </button>
  )
}
