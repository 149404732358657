import React from 'react'

import { useBlockScroll } from '@hooks/useBlockScroll'

import type { LoaderProps } from './Loader.props'
import './Loader.scss'

export function Loader({ ...props }: LoaderProps) {
  return (
    <div className="loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  )
}

export function AppLoader() {
  useBlockScroll()

  return (
    <div className="appLoader">
      <Loader />
    </div>
  )
}
