/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint: 'https://xpxkn3kouzfedohqjawizpvu24.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-nqcofdqesjcnvcowzldsf2nfxq',
  aws_cognito_identity_pool_id: 'eu-central-1:b03a0c40-5b7a-4914-b8b2-acd85951904c',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_Zhbrb7xRp',
  aws_user_pools_web_client_id: '23cm7986ocj1nmlg3fhr1deeiu',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'spyf292150af3c64c1b935872acebcbc1dc143832-dev',
  aws_user_files_s3_bucket_region: 'eu-central-1',
}

export default awsmobile
