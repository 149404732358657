import { fetchAuthSession } from 'aws-amplify/auth'

import type { AuthUser } from '@aws-amplify/auth'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchUser = createAsyncThunk('user/roles', async (_, thunkAPI) => {
  try {
    const session = await fetchAuthSession()

    return {
      groups: (session.tokens?.accessToken.payload['cognito:groups'] || []) as string[],
    }
  } catch (error) {
    console.error(error)
    return thunkAPI.rejectWithValue('Error fetchAuthSession')
  }
})

export interface IGlobalState {
  user?: AuthUser
  roles?: string[]
  loading: boolean
}

const initialState: IGlobalState = {
  user: undefined,
  loading: false,
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.roles = action.payload.groups
        state.loading = false
      })
      .addCase(fetchUser.rejected, (state) => {
        state.loading = false
      })
  },
})

export const globalActions = globalSlice.actions

export const globalReducer = globalSlice.reducer
