import { useAuthenticator } from '@aws-amplify/ui-react'

import { useCreateLogMutation } from '@/store/logs/logs.api'

export type EventType =
  | 'user-sign-in' //Авторизація
  | 'user-sign-out' //Вихід з сервісу
  | 'user-context-menu' //Спроба відкрити контестне меню
  | 'user-save-view' //Спроба зберегти або переглянути код сторінки
  | 'user-devtools-opened' //Імовірно відкритий DevTools
  | 'navigate-access-denied' //Спроба перейти по забороненому URL
  | 'home-filter-apply' //Застосовано фільтр на головній сторінці
  | 'home-filter-share' //Скопійовно посилання на головну сторінку із фільтрацією
  | 'home-filter-our-post' //Застосовано фільтр "Всі/наші пости"
  | 'post-create' //Пост створено
  | 'post-edit' //Пост відредаговано
  | 'post-share' //Скопійовано посилання на пост
  | 'post-open-info' //Відккрита повна інформація про пост
  | 'post-follow-post-link' //Перехід на посилання поста
  | 'post-follow-fan-link' //Перехід на посилання фан гр.
  | 'post-open-link' //Відкритий повний огляд посилання на пост
  | 'post-open-autologin' //Відкритий повний огляд автологін посилання
  | 'post-generate-media' //Згенеровано посилання на звантаження креативу
  | 'post-generate-archive' //Згенеровано посилання на звантаження архіву
  | 'post-generate-archive-landing' //Згенеровано посилання на звантаження архіву лендінгу
  | 'post-generate-archive-video' //Згенероване посилання на завантаження архіву із відео
  | 'post-generate-landing' //Згенеровано посилання на ленд/преленд/хз-ленд
  | 'post-read-redirectPath' //Переглянуто зміст redirect-path
  | 'post-delete' //Видалено пост
  | 'metrics-efficiency-filter' //Застосовано фільтр для метрик "Spyer Efficerncy"
  | 'metrics-filter-apply' //Застосовано фільтр на сторінці метрик
  | 'metrics-tab-change' //Перегляд табів на сторінці метрик "AdsAnalytics"
  | 'metrics-graph-click' //На сторінці метрик "AdsAnalytics" клік по графіку (копіювання назви)
  | 'append-add' //Створено country/celebrity/funnel
  | 'append-edit' //Відредаговано country/celebrity/funnel
  | 'append-delete' //Видалено country/celebrity/funnel

export const logTypes: Record<EventType, string> = {
  'user-sign-in': 'Авторизація',
  'user-sign-out': 'Вихід з сервісу',
  'user-context-menu': `Спроба відкрити контестне меню`,
  'user-save-view': `Спроба зберегти або переглянути код сторінки`,
  'user-devtools-opened': `Імовірно відкритий DevTools`,
  'navigate-access-denied': `Спроба перейти по забороненому URL`,
  'home-filter-apply': `Застосовано фільтр на головній сторінці`,
  'home-filter-share': `Скопійовно посилання на головну сторінку із фільтрацією`,
  'home-filter-our-post': `Застосовано фільтр "Всі/наші пости"`,
  'post-create': `Пост створено`,
  'post-edit': `Пост відредаговано`,
  'post-share': `Скопійовано посилання на пост`,
  'post-open-info': `Відккрита повна інформація про пост`,
  'post-follow-post-link': `Перехід на посилання поста`,
  'post-follow-fan-link': `Перехід на посилання фан гр.`,
  'post-open-link': `Відкритий повний огляд посилання на пост`,
  'post-open-autologin': `Відкритий повний огляд автологін посилання`,
  'post-generate-media': `Згенеровано посилання на звантаження креативу`,
  'post-generate-archive': `Згенеровано посилання на звантаження архіву`,
  'post-generate-archive-landing': `Згенеровано посилання на звантаження архіву лендінгу`,
  'post-generate-archive-video': `Згенероване посилання на завантаження архіву із відео`,
  'post-generate-landing': `Згенеровано посилання на ленд/преленд/хз-ленд`,
  'post-read-redirectPath': 'Переглянуто зміст redirect-path',
  'post-delete': 'Видалено пост',
  'metrics-efficiency-filter': `Застосовано фільтр для метрик "Spyer Efficerncy"`,
  'metrics-filter-apply': `Застосовано фільтр на сторінці метрик`,
  'metrics-tab-change': `Перегляд табів на сторінці метрик "AdsAnalytics"`,
  'metrics-graph-click': `На сторінці метрик "AdsAnalytics" клік по графіку (копіювання назви)`,
  'append-add': `Створено country/celebrity/funnel`,
  'append-edit': `Відредаговано country/celebrity/funnel`,
  'append-delete': `Видалено country/celebrity/funnel`,
}

export function useLogger() {
  const { user } = useAuthenticator((ctx) => [ctx.user])

  const [createLog] = useCreateLogMutation()

  const handleLog = (event: EventType, payload?: Record<string, any>) => {
    if (process.env.NODE_ENV !== 'production') return

    const resource = event.split('-')[0]

    createLog({
      user_id: user.userId,
      user_name: user.username,
      event,
      resource,
      payload,
    })
      .unwrap()
      .catch(console.error)
  }

  return {
    logger: handleLog,
  }
}
