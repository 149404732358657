import { useEffect, useRef, useState } from 'react'

import { useLogger } from '@hooks/useLogger'
import { usePermissions } from '@hooks/usePermissions'

import { useAppSelector } from '@/store/hooks'

// Розширюємо інтерфейси Window та Console
declare global {
  interface Window {
    devtools?: {
      open: boolean
    }
  }
  interface Console {
    firebug?: any
  }
}

export const useDevToolsDetector = (onDevToolsOpen?: () => void) => {
  const { roles } = useAppSelector((state) => state.global)

  const { access } = usePermissions()
  const { logger } = useLogger()

  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false)
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const prevDimensionsRef = useRef({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    if (!roles) return

    const handleDevToolsOpen = () => {
      if (!isDevToolsOpen && !access('openDevTools')) {
        const isLogged = sessionStorage.getItem('_isLogged_user_devtools_open')

        if (isLogged !== 'true') {
          logger('user-devtools-opened', { message: `DevTools відкриті` })
          sessionStorage.setItem('_isLogged_user_devtools_open', 'true')
        }

        window.alert('Sho nado tyt? 🤬 Zakroy devTools, BUSTRO! ☠️')

        onDevToolsOpen && onDevToolsOpen()
        setIsDevToolsOpen(true)
      }
    }

    const detectDevTools = () => {
      // Метод 1: Безпечна перевірка властивості window.devtools
      if ('devtools' in window && window.devtools?.open) {
        handleDevToolsOpen()
        return
      }

      // Метод 2: Перевірка часу виконання console.log
      const startTime = performance.now()
      console.log()
      const endTime = performance.now()
      if (endTime - startTime > 100) {
        handleDevToolsOpen()
        return
      }

      // Метод 3: Безпечна перевірка наявності додаткових властивостей console
      if ('firebug' in console) {
        handleDevToolsOpen()
        return
      }

      // Метод 4: Перевірка розміру вікна
      const threshold = 160
      const widthThreshold = window.outerWidth - window.innerWidth > threshold
      const heightThreshold = window.outerHeight - window.innerHeight > threshold
      if (widthThreshold || heightThreshold) {
        handleDevToolsOpen()
        return
      }

      setIsDevToolsOpen(false)
      sessionStorage.setItem('_isLogged_user_devtools_open', 'false')
    }

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current)
      }

      resizeTimeoutRef.current = setTimeout(() => {
        const currentDimensions = { width: window.innerWidth, height: window.innerHeight }
        const prevDimensions = prevDimensionsRef.current

        const widthDiff = Math.abs(currentDimensions.width - prevDimensions.width)
        const heightDiff = Math.abs(currentDimensions.height - prevDimensions.height)

        const threshold = 160
        if ((widthDiff > threshold || heightDiff > threshold) && !document.fullscreenElement) {
          detectDevTools()
        }

        prevDimensionsRef.current = currentDimensions
      }, 500)
    }

    window.addEventListener('resize', handleResize)
    detectDevTools()

    const intervalId = setInterval(detectDevTools, 1000)

    return () => {
      window.removeEventListener('resize', handleResize)

      clearInterval(intervalId)

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current)
      }
    }
  }, [roles, isDevToolsOpen])

  return isDevToolsOpen
}
