import { Amplify } from 'aws-amplify'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Authenticator } from '@aws-amplify/ui-react'

import { ReactComponent as ToastErrorIcon } from '@assets/icons/toast_error.svg'
import { ReactComponent as ToastPendingIcon } from '@assets/icons/toast_pending.svg'
import { ReactComponent as ToastSuccessIcon } from '@assets/icons/toast_success.svg'
import '@assets/styles/main.scss'

import App from '@/App'
import { store } from '@/store'

import awsExports from './aws-exports'
import reportWebVitals from './reportWebVitals'

Amplify.configure(awsExports)

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Provider store={store}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="colored"
          icon={({ type }) => {
            switch (type) {
              case 'success':
                return <ToastSuccessIcon />
              case 'error':
                return <ToastErrorIcon />
              default:
                return <ToastPendingIcon />
            }
          }}
        />
      </Provider>
    </Authenticator.Provider>
  </React.StrictMode>,
)

reportWebVitals()
