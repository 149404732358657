import { useAuthenticator } from '@aws-amplify/ui-react'

import { permissions } from '@assets/helpers/static-info'

import { useAppSelector } from '@/store/hooks'

export function usePermissions() {
  const { user } = useAuthenticator((context) => [context.user, context.route, context.signOut])
  const { roles } = useAppSelector((state) => state.global)

  const access = (funcKey: keyof typeof permissions) => {
    const roleIncludes = roles?.some((role) => permissions[funcKey].roles.includes(role.toLowerCase()))
    const usernameIncludes = permissions[funcKey].nickname.includes(user?.username)

    return roleIncludes || usernameIncludes
  }

  return {
    access,
  }
}
