import { Hub } from 'aws-amplify/utils'
import { useEffect } from 'react'

import { useCreateLogMutation } from '@/store/logs/logs.api'

export function AuthLogging() {
  const [createLog] = useCreateLogMutation()

  useEffect(() => {
    const handleAuthEvent = (data: any) => {
      switch (data.payload.event) {
        case 'signedIn':
          console.log('User signed in', data)

          createLog({
            user_id: data.payload.data.userId,
            user_name: data.payload.data.username,
            event: 'user-sign-in',
            resource: 'auth',
            payload: undefined,
          })
            .unwrap()
            .then(() => {
              localStorage.setItem('_dmnd-username', data.payload.data.username)
              localStorage.setItem('_dmnd-userId', data.payload.data.userId)
            })
          break
        case 'signedOut':
          console.log('User signed out', data)

          createLog({
            user_id: localStorage.getItem('_dmnd-userId') ?? '',
            user_name: localStorage.getItem('_dmnd-username') ?? '',
            event: 'user-sign-out',
            resource: 'auth',
            payload: undefined,
          })
            .unwrap()
            .then(() => {
              localStorage.removeItem('_dmnd-userId')
              localStorage.removeItem('_dmnd-username')
            })

          break
      }
    }

    Hub.listen('auth', handleAuthEvent)
  }, [])

  return null
}
